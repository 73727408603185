export enum EEnvKey {
    PORT = 'PORT',
    CHROMIUM_BINARY_PATH = 'CHROMIUM_BINARY_PATH',
    CACHE_ENABLED = 'CACHE_ENABLED',
    KEYDB_IP = 'KEYDB_IP',
    KEYDB_PORT = 'KEYDB_PORT',
    NEXT_PUBLIC_ENVIRONMENT = 'NEXT_PUBLIC_ENVIRONMENT',
    NEXT_PUBLIC_TITLE = 'NEXT_PUBLIC_TITLE',
    NEXT_PUBLIC_NEHNUTELNOSTI_URL = 'NEXT_PUBLIC_NEHNUTELNOSTI_URL',
    NEXT_PUBLIC_NEHNUTELNOSTI_NEST_API_URL = 'NEXT_PUBLIC_NEHNUTELNOSTI_NEST_API_URL',
    NEXT_PUBLIC_OAUTH_API_URL = 'NEXT_PUBLIC_OAUTH_API_URL',
    NEXT_PUBLIC_OAUTH_CALLBACK_URL = 'NEXT_PUBLIC_OAUTH_CALLBACK_URL',
    NEXT_PUBLIC_PLATFORM_PROJECT_NAME = 'NEXT_PUBLIC_PLATFORM_PROJECT_NAME',
    NEXT_PUBLIC_MAP_TILE_LAYER_URL = 'NEXT_PUBLIC_MAP_TILE_LAYER_URL',
    NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITEKEY = 'NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITEKEY',
    NEXT_PUBLIC_GOOGLE_RECAPTCHA_INVISIBLE_SITEKEY = 'NEXT_PUBLIC_GOOGLE_RECAPTCHA_INVISIBLE_SITEKEY',
    NEXT_PUBLIC_ASSETS_SERVER = 'NEXT_PUBLIC_ASSETS_SERVER',
    NEXT_PUBLIC_FB_PIXEL_ID = 'NEXT_PUBLIC_FB_PIXEL_ID',
    NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID = 'NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID',
    NEXT_PUBLIC_RTB_HOUSE_ID = 'NEXT_PUBLIC_RTB_HOUSE_ID',
    NEXT_PUBLIC_NEHNUTELNOSTI_ADMIN_URL = 'NEXT_PUBLIC_NEHNUTELNOSTI_ADMIN_URL',
    NEXT_PUBLIC_PLATFORM_PARAMETER_SERVICE_URL = 'NEXT_PUBLIC_PLATFORM_PARAMETER_SERVICE_URL',
    NEXT_PUBLIC_SP_PRIVACY_MANAGER_ID = 'NEXT_PUBLIC_SP_PRIVACY_MANAGER_ID',
    NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID = 'NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID',
    NEXT_PUBLIC_NEHNUTELNOSTI_ADMIN_API_URL = 'NEXT_PUBLIC_NEHNUTELNOSTI_ADMIN_API_URL',
}
