import { IFacebookPixelTracker, IGemiusPageView, IGemiusTracker, IGtmEvent, IGtmTracker, ILivemonitorEvent, ILivemonitorTracker, IRtbHouseEvent, IRtbHouseTracker } from 'modules/analytics/IAnalytics';
import ISession from 'modules/session/interfaces/ISession';

import { atom } from 'recoil';
import { IOpenState as IOpenMenuState } from 'modules/state/app/hook/useOpenMenuState';
import { IState as IGdprCookieBarState } from 'modules/state/app/hook/gdpr/useCookieBar';

export const loadingCountState = atom<number>({
    key: 'app-loadingCount',
    default: 0
});

export const headerLoaderState = atom<number>({
    key: 'app-headerLoader',
    default: 0
});

export const openMenuState = atom<IOpenMenuState>({
    key: 'app-openMenu',
    default: {}
});

export const sessionState = atom<ISession>({
    key: 'app-session',
    default: null
});

export const isSessionLoadingState = atom<boolean>({
    key: 'app-isSessionLoading',
    default: true
});

export const analyticsGtmTrackerState = atom<IGtmTracker>({
    key: 'app-analyticsGtmTracker',
    default: null
});

export const analyticsGtmEventQueueState = atom<IGtmEvent[]>({
    key: 'app-analyticsGtmEvent',
    default: []
});

export const analyticsRtbHouseTrackerState = atom<IRtbHouseTracker>({
    key: 'app-analyticsRtbHouseTracker',
    default: null
});

export const analyticsRtbHouseEventQueueState = atom<IRtbHouseEvent[]>({
    key: 'app-analyticsRtbHouseEvent',
    default: []
});

export const analyticsLivemonitorTrackerState = atom<ILivemonitorTracker>({
    key: 'app-analyticsLivemonitorTracker',
    default: null
});

export const analyticsLivemonitorEventQueueState = atom<ILivemonitorEvent[]>({
    key: 'app-analyticsLivemonitorEvent',
    default: []
});

export const analyticsFacebookPixelTrackerState = atom<IFacebookPixelTracker>({
    key: 'app-analyticsFacebookPixelTracker',
    default: null
});

export const analyticsFacebookPixelEventQueueState = atom<unknown[][]>({
    key: 'app-analyticsFacebookPixelEvent',
    default: []
});

export const analyticsFacebookPixePageViewQueueState = atom<number[]>({
    key: 'app-analyticsFacebookPixelPageView',
    default: []
});

export const analyticsGemiusTrackerState = atom<IGemiusTracker>({
    key: 'app-analyticsGemiusTracker',
    default: null
});

export const analyticsGemiusPageViewQueueState = atom<IGemiusPageView[]>({
    key: 'app-analyticsGemiusPageViewQueue',
    default: []
});

export const gdprCookieBarState = atom<IGdprCookieBarState>({
    key: 'app-gdprCookieBar',
    default: {
        isAllowed: {
            googleTagManager: false,
            facebookPixel: false,
            gemius: false,
            rtbHouse: false,
            livemonitor: false,
        }
    }
});

export const appUserIsLoggedIn = atom<boolean|undefined>({
    key: 'app-userIsLoggedIn',
    default: undefined
});
